@import "/src/utils/styles/main.scss";

.process {
  margin-bottom: 10px;
  text-align: center;

  &__btn {
    width: 100%;
    @include mainBtn;
  }

  &:last-child {
    margin-bottom: 0;
  }
}
