@import "/src/utils/styles/main.scss";

.form {
  max-width: 450px;
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: column;

  &__btn-wrap {
    position: relative;
    display: flex;
    flex-direction: column;
  }

  &__btn-submit {
    @include mainBtn;
    width: 100%;
    margin: 40px 0 20px;
  }

  &__btn-submit-err {
    @include caption;
    position: absolute;
    top: 20px;
    width: 100%;
    color: $normalRed;
    text-align: center;
  }

  &__link {
    @include smallBtnText;
    margin: 0 auto;
    color: $black;
    text-decoration: none;
    transition: $transition;

    &:hover {
      opacity: 0.7;
    }
  }
}
