@import "/src/utils/styles/main.scss";

.board {
  max-width: 400px;
  width: 100%;
  height: auto;
  padding: 10px;
  background-color: $gray2;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;

  &__title {
    margin: 0;
    @include title;
  }

  &__subtitle {
    margin-top: 10px;
    @include subtitle;
  }

  &__head {
    max-width: 400px;
    width: 100%;
    margin-bottom: 2px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__path {
    margin: 20px 0 0;
    padding-left: 20px;
    align-self: start;
    @include caption;
  }

  &__err-message {
    @include caption;
    margin-top: 10px;
    color: $normalRed;
  }

  &__tasks {
    width: 100%;
    margin: 10px 0 0;
    padding: 0;
    list-style-type: none;
  }
}
