@import "/src/utils/styles/main.scss";

.modal {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: 0.5s;

  &__content {
    width: 100%;
    max-width: 450px;
    padding: 35px 20px 20px;
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: $gray2 !important;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 20px !important;
    outline: 0;
    transform: scale(0);
    transition: 0.25s;
  }

  &__title {
    @include subtitle;
    margin: 0 0 5px;
    color: $darkBlue;
    text-align: center;
  }

  &__btn-close {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 0;
    right: 0;
    margin: 16px 16px 0 0;
    padding: 0;
    border: none;
    background-image: url(../../../images/icons/cross.svg);
    background-color: transparent;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer !important;
  }

  &__wrapper {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__key {
    @include caption;
    text-align: start;
  }

  &__value {
    @include listMainText;
    margin: 5px 0 0;
    text-align: start;
  }
}

.modal.modal_opened {
  pointer-events: all;
  opacity: 1;
}

.modal__content.modal__content_opened {
  transform: scale(1);
}
