@import "/src/utils/styles/main.scss";

.form-field-search {
  &__label {
    @include listMainText;
    margin: 15px 0 25px;
    position: relative;
    display: flex;
    flex-direction: column;
  }

  &__wrap {
    width: 100%;
    display: flex;
    flex-direction: row;
  }

  &__input {
    @include listMainText;
    width: 100%;
    margin: 5px 0 0;
    padding: 10px;
    border: 1px solid $gray2;
    box-sizing: border-box;
    border-radius: 6px 0px 0px 6px;

    &_disabled {
      @include listMainText;
      width: 100%;
      margin: 5px 0 0;
      padding: 10px;
      border: 1px solid $gray2;
      box-sizing: border-box;
      border-radius: 6px 0px 0px 6px;
      background-color: $gray4;
    }
  }

  &__btn-submit {
    @include mainText;
    cursor: pointer;
    padding: 11px 49px;
    border-radius: 6px;
    border: none;
    color: $white;
    background-color: $skyBlue;
    -webkit-transition-duration: 0.4s;
    transition-duration: 0.4s;

    &:hover {
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12), 0px 4px 16px rgba(0, 140, 255, 0.4);
    }

    &:focus {
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12), 0px 4px 16px rgba(0, 140, 255, 0.4);
    }

    &:active {
      box-shadow: inset 4px -4px 4px rgba(255, 255, 255, 0.5), inset -4px 4px 4px rgba(255, 255, 255, 0.5);

      &:focus {
        box-shadow: inset 4px -4px 4px rgba(255, 255, 255, 0.5), inset -4px 4px 4px rgba(255, 255, 255, 0.5);
      }
    }
    margin: 5px 0 0;
    padding: 10px;
    border: none;
    border-radius: 0px 6px 6px 0px;

    &_disabled {
      @include mainText;
      margin: 5px 0 0;
      padding: 10px;
      border-radius: 0px 6px 6px 0px;
      color: $white;
      border: none;
      background-color: $lightBlue;
    }
  }

  &__input-err {
    @include caption;
    position: absolute;
    top: 65px;
    color: $normalRed;
  }
}

/*

*/
