@import "/src/utils/styles/main.scss";

.content {
  width: 100%;
  padding: 20px;
  margin: 0;

  &_login {
    max-width: 400px;
    margin: 20px auto 0;
  }

  &__title {
    margin: 0;
    @include title;
  }

  &__wrap-processes {
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__err-message {
    @include mainText;
    color: $normalRed;
  }

  &__processes {
    width: 100%;
    max-width: 350px;
    margin: 10px 0 0;
    padding: 0;
    list-style-type: none;
  }
}
