@import "/src/utils/styles/main.scss";

.form-search {
  max-width: 450px;
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid $gray4;
}
