// blue
$darkBlue: #005ba9;
$deepBlue: #0077d8;
$skyBlue: #008cff;
$normalBlue: #80c5ff;
$lightBlue: #cee9ff;
$whiteBlue: #f2f9ff;

// red
$normalRed: #d41d3c;
$coral: #ff584e;

// orange
$beige: #fffaea;
$yellow: #fff9d7;
$gold: #c19f33;
$lightOrange: #ffad00;
$normalOrange: #ec6500;

// green
$normalGreen: #17a854;
$lightGreen: #eff9e9;

// gray
$white: #ffffff;
$gray1: #fafafa;
$gray2: #f3f3f3;
$gray3: #e7e7e7;
$gray4: #c5c6c6;
$gray5: #9c9c9d;
$gray6: #6e6e6e;
$gray7: #3a3a39;
$black: #000000;
