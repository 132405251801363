@import "/src/utils/styles/main.scss";

.task {
  width: 100%;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }

  &__btn {
    @include boardBtn;
    width: 100%;
    padding: 15px;
  }

  &__title {
    @include subtitle;
    margin: 0 0 5px;
    color: $darkBlue;
  }

  &__wrap-val {
    margin-bottom: 5px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__key {
    @include caption;
    text-align: flex-start;
  }

  &__value {
    @include listMainText;
    margin: 5px 0 0;
    text-align: flex-start;

    &_err {
      color: $normalRed;
    }
  }
}
