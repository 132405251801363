@import "/src/utils/styles/main.scss";

.card-info {
  display: flex;
  justify-content: space-between;

  &__card {
    max-width: 350px;
    width: 100%;
    height: max-content;
    margin: 35px 0 0;
    padding: 10px;
    display: flex;
    flex-direction: column;
    order: 2;
    background-color: $gray1;
    border: 1px solid $normalBlue;
    border-radius: 10px;
  }

  &__title {
    @include subtitle;
    margin: 0 0 5px;
    color: $darkBlue;
  }

  &__wrap-val {
    margin-bottom: 5px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__card-key {
    @include caption;
    margin: 0;
    text-align: flex-start;
  }

  &__card-value {
    @include listMainText;
    margin: 2px 0 0;
    text-align: flex-start;
  }
}
