@import "/src/utils/styles/main.scss";

.header {
  padding: 25px 30px;

  &__menu {
    display: flex;
    justify-content: space-between;
  }

  &__nav-location {
  }

  &__location-items {
    display: flex;
    flex-direction: row;
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  &__location-item {
    margin-right: 15px;

    &:last-child {
      margin-right: 0;
    }
  }

  &__btn-navlink {
    @include mainText;

    padding: 5px 15px;
    display: flex;
    align-items: center;
    background-color: $gray2;
    border-radius: 25px;
    text-decoration: none;
    transition: $transition;

    &:hover {
      background-color: $white;
      text-decoration: none;
    }

    &_active {
      text-decoration: none;
      padding-left: 45px;
      background-image: url(../../images/Logo.svg);
      background-color: $white;
      background-position: left;
      background-repeat: no-repeat;
      background-size: 25px;
      background-position: 10% 50%;
    }
  }

  &__nav-sign {
  }

  &__btn-sign {
    @include mainText;

    padding: 5px 15px;
    display: flex;
    align-items: center;
    color: $black;
    text-decoration: none;
    transition: $transition;

    &:hover {
      color: $skyBlue;
    }
  }
}
